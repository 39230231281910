/**
 * Single
**/

.article-body{
    margin-top: 100px;
    margin-bottom: 75px;

    h2{
        font-size: 32px;
        margin-bottom: 30px;
    }

    h3,
    h4{
        margin-bottom: 25px;
    }

    p{
        margin-bottom: 30px;
    }

    a:not(.wp-element-button){
        color: $blue;
        transition: color 350ms ease;

        &:hover{
            color: $orange;
        }
    }

    ul{
        li{
            list-style-type: none;
            position: relative;

            &:before{
                content: '';
                position: absolute;
                left: -25px;
                top: 14px;
                height: 5px;
                width: 5px;
                border-radius: 5px;
                background-color: $blue;
            }
        }
    }    

    .intro-tekst{
        color: $green;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
    }

    .wp-block-image img{
        border-radius: 8px !important;
        width: 100%;
        margin-bottom: 15px;
    }

    .wp-block-buttons > .wp-block-button{
        margin-bottom: 30px;
    }

    .wp-block-button__link{
        @extend .btn;
        background-color: $grey;
        border: 2px solid $grey;

        &:hover{
            background-color: transparent;
            color: $grey;
        }
    }

    @include media-breakpoint-up(lg){
        p,
        ul,
        ol,
        h2,
        h3,
        h4,
        h5,
        h6,
        .wp-block-table,
        .wp-block-buttons{
            margin-left: 150px;        
            margin-right: 150px;
        }
    }
    
}

/**
 * Campus/Leefgroepn
**/

.info-sheet-campus__inner,
.related-living-groups__inner{
    height: 100%;
    padding: 45px;
}

.info-sheet-campus__inner{ 
    h3{
        color: $green;
        font-size: 24px;
    }
}

.extra-info--page-leefgroep{
    .info-sheet-campus__inner{    
        margin-left: 150px;
        margin-right: 150px;
    }
}

.related-living-groups__inner{
    ul{
        list-style-type: none;
        padding-left: 0;
        margin-top: 50px;
    }

    li{
        border-bottom: 1px solid rgba($blue, .15);
    }

    li a{
        color: $blue;
        display: block;
        font-size: 22px;
        font-weight: 500;
        text-decoration: none;
        padding-top: 10px;
        padding-bottom: 10px;

        position:relative;
        transition: all 350ms ease;

        &:after{
            content:'';
            position: absolute;
            background-image: url('images/arrow-right-blue.png');
            background-size: 100%;
            background-repeat: no-repeat;            
            width: 20px;
            height: 12px;
            top: 20px;
            right: 30px;  
            
            transition: all 350ms ease;            
        }

        &:hover{
            background-color: rgba($blue, .1);
            padding-left: 25px;

            &:after{
                right: 15px;
            }
        }
    }    
}

/**
 * Call to action
**/

.call-to-action{
    .bg--orange-5{
        border: 1px solid rgba($orange, .15);
    }

    &__inner{
        padding: 45px;

        @include media-breakpoint-up(md){
            display: flex;
            align-content: end;
            align-items: center;
        }
    }

    .right {
        width: 100%;

        @include media-breakpoint-up(md){
            p{
                text-align: right;
                margin-right: 50px;            
            }
        }

        @include media-breakpoint-up(lg){
            p{
                margin-right: 100px;
            }
        }
    }

    .btn{
        position: relative;

        &:after{
            content: '';
            position: absolute;
            background-image: url('images/curl-arrow.png');
            width: 66px;
            height: 46px;
            background-size: 100%;
            top: 40px;
            left: 50px;
        }

        @include media-breakpoint-down(md){
            margin-top: 25px;
        }
    }    
}