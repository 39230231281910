/**
 * Header
**/

.header{
    background-color: $grey-light;
    background-size: cover;
    background-position: center;
}

.header-intro{
    padding-top: 125px;
    padding-bottom: 125px;

    h1{
        opacity: 0;
        transform: translateY(25px);

        animation-name: reveal-in;
        animation-timing-function: ease;
        animation-fill-mode: forwards;
        animation-duration: 1.5s;
        animation-delay: 1s;        
     
    }

    &__content{
        font-size: 24px;
        line-height: 34px;
        max-width: 430px;
        margin-bottom: 45px;

        opacity: 0;
        transform: translateY(15px);

        animation-name: reveal-in;
        animation-timing-function: ease;
        animation-fill-mode: forwards;
        animation-duration: 1.5s;
        animation-delay: 1.2s;            
    }

    .btn{
        position: relative;

        &:after{
            content: '';
            position: absolute;
            background-image: url('images/curl-arrow.png');
            width: 66px;
            height: 46px;
            background-size: 100%;
            top: 40px;
            left: 50px;
        }

        opacity: 0;
        transform: translateY(-20px);

        animation-name: reveal-in;
        animation-timing-function: ease;
        animation-fill-mode: forwards;
        animation-duration: 1.5s;
        animation-delay: 1.5s;             
    }

    &--sub{
        padding-top: 105px;
        padding-bottom: 105px;
    }
}

@keyframes reveal-in{
    from {opacity: 0;}
    to {opacity: 1; transform: translateY(0px);}  
}
