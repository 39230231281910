/**
 * Front
**/

.elevator-pitch{
    &__content{
        display: flex;
        align-items: center;
    }

    img{
        width: 100%;
    }

    h3{
        margin-top: 35px;

        @include media-breakpoint-up(md){
            margin-top: 0;
        }
    }
}

/**
 * Campussen
**/
.our-campuses{
    padding-bottom: 75px;
}

.campus-item{
    &__image-wrapper{
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        overflow: hidden;        
    }

    &__content{
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        overflow: hidden;     
        padding: 45px;
        margin-bottom: 25px;           
    }

    img{
        width: 100%;
    }

    p:first-of-type{
        max-width: 450px;
        margin-bottom: 35px;        
    }
}

/**
 * Leefgroepen
**/
.living-groups{
    p{
        padding-right: 80px;    
    }

    ul{
        list-style-type: none;
        padding-left: 0;
        margin-top: 50px;
        margin-bottom: 35px;

        @include media-breakpoint-up(md){
            margin-bottom: 15px;
        }
    }

    li{
        border-bottom: 1px solid rgba($orange, .05);
    }

    li a{
        color: $orange;
        display: block;
        font-size: 22px;
        font-weight: 500;
        text-decoration: none;
        padding-top: 10px;
        padding-bottom: 10px;

        position:relative;
        transition: all 350ms ease;

        &:after{
            content:'';
            position: absolute;
            background-image: url('images/arrow-right.png');
            background-size: 100%;
            background-repeat: no-repeat;            
            width: 20px;
            height: 12px;
            top: 20px;
            right: 30px;  
            
            transition: all 350ms ease;            
        }

        &:hover{
            background-color: rgba($orange, .05);
            padding-left: 25px;

            &:after{
                right: 15px;
            }
        }
    }
}

/**
 * Nieuws
**/
.news{
    h2{
        margin-bottom: 45px;
    }
}

.news-item{
    &__image-wrapper{
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        overflow: hidden;        
    }

    &__content{
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        overflow: hidden;     
        padding: 25px;
        margin-bottom: 15px;           
    }

    h3{
        color: $blue;
        font-size: 26px;
        margin-top: 20px;
    }
    
    img{
        width: 100%;
    }

    .btn{
        margin-top: 20px;
    }
}

