/**
 * Typography
**/

*{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body{
    color: $grey;
    font-family: $primary-font;
    font-size: 17px;
    line-height: 28px;    
}

h1,
h2,
h3,
h4,
h5,
h6{
    color: $grey;
    font-weight: 600;
    text-transform: uppercase;
}

h1{
    font-size: 56px;
    letter-spacing: 2px;
}

h2{
    font-size: 28px;
    letter-spacing: 1px; 

    @include media-breakpoint-up(md){
        font-size: 38px;        
    }

    @include media-breakpoint-up(lg){
        font-size: 52px;        
    }

}

h3{
    font-size: 24px;
    letter-spacing: 1px;
    line-height: 29px;
    margin-bottom: 20px;

    @include media-breakpoint-up(md){
        font-size: 30px;
        line-height: 39px;
    }
}

p{
    color: $grey;
    font-size: 17px;
    line-height: 28px;
}