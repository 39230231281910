/**
 * Utils
**/

/**
 * Backgrounds
 **/
 @each $name, $color in (
    'orange': $orange,
    'blue': $blue,
    'green': $green,
    'white': $white,
    ) {

	.bg--#{$name} {
		background-color: $color;
  	}

    .bg--#{$name}-5 {
		background-color: rgba($color, 0.05);
  	}    
}

/**
 * Blocks
**/
@each $name, $size in ('10': 10, '20': 20, '35': 35, '50': 50, '75': 75, '100': 100, '120': 120, '150': 150) {
	$mobile-size: calc($size/2);
    
    .block-#{$name} {
		padding-top: #{$size}px;
		padding-bottom: #{$size}px;
  	}

    @include media-breakpoint-down(md){
        .block-#{$name} {
            padding-top: #{$mobile-size}px;
            padding-bottom: #{$mobile-size}px;
          }        
    }
}

/**
 * Buttons
**/
.btn{
    display: inline-block;
    font-size: 15px;
    font-weight: 600;
    padding: 10px 50px;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 350ms ease;
    letter-spacing: 0.5px;

    &--blue{
        background-color: $blue;
        border-radius: 100px;
        border: 2px solid $blue;
        color: $white;

        &:hover{
            background-color: transparent;
            color: $blue;
        }
    }

    &--orange{
        background-color: $orange;
        border-radius: 100px;
        border: 2px solid $orange;
        color: $white;

        &:hover{
            background-color: transparent;
            color: $orange;
        }
    }

    &--green{
        background-color: $green;
        border-radius: 100px;
        border: 2px solid $green;
        color: $white;

        &:hover{
            background-color: transparent;
            color: $green;
        }
    }    

}


/**
 * Classes
 **/
 .text-center{
    text-align: center !important;
 }

 .border-8{
    border-radius: 8px;
 }

 .border-30{
    border-radius: 30px;
 }
