/**
 * Typekit font (Proxima Nova)
**/

@import url("https://use.typekit.net/tjq0kio.css");

/**
 * Fonts
**/
$primary-font: "proxima-nova", sans-serif;;

/**
 * Global variables
 **/

$image_path: 'images/';

/**
 * Colors
 **/

$orange: #CB553A;
$blue: #005F96;
$green: #80A27C;

$white: #FFFFFF;
$grey-light: #F2F2F2;
$grey: #404147;
$black: #000000;