/**
 * Footer
**/

#footer{
    padding-top: 75px;
    padding-bottom: 35px;

    h5{
        font-size: 24px;
        margin-bottom: 25px;
        margin-top: 30px;

        @include media-breakpoint-up(md){
            margin-top: 0;
        }
    }

    ul{
        list-style-type: none;
        padding-left: 0;

        li{
            padding-bottom: 5px;            
        }

        li a{
            color: $grey;
            text-decoration: none;

            transition: all 350ms ease;

            &:hover{
                color: $orange;
            }
        }
    }
    
    strong{
        color: $blue;
    }
}

.footer-final{
    padding-top: 10px;
    padding-bottom: 10px;

    a{
        color: $grey;
        font-size: 12px;
    }
}