/**
 * Mobile only
 **/

@include media-breakpoint-down(lg) {
	#menu-entries{
		display: none !important;
	
		&.show{
			display: block !important;
			width: 100% !important;
		}

		ul{
			width: 100%;
		}

		@include media-breakpoint-down(lg){
			background-color: $white;
			margin-top: 25px;
			border-radius: 8px;
			padding: 25px 0px;			
		}
	}

	.navbar{
		display: block !important;
	}

	.navbar-toggler{
		float: right;
	}
}

/**
 * Main navigation
**/
#nav-main{
	padding-top: 30px;

	.dropdown-menu{
		border: 0px;
		border-radius: 8px;
		padding-top: 15px;
		padding-bottom: 15px;
		left: 35px;

		.nav-link{
			margin-left: 90px;
			
			@include media-breakpoint-up(lg){
				text-align: center;
				margin-left: 0;			
			}
		}
	}
}

.navbar-brand{
	margin-right: 150px;
}

.nav-link{
	color: $grey;
	font-size: 15px;
	margin-left: 30px;
	transition: all 350ms ease;

	&:hover{
		color: $orange;
	}

	@include media-breakpoint-up(xl){
		margin-left: 60px;
	}
}

#menu-entries{
	.dropdown.child{
		position: relative !important;
		.dropdown-sub{
			position: absolute !important;
			right: 0;
		}
	}
}
