/**
 * Body
**/

.row:before, .row:after {
	display: none !important;
}

img{
	max-width: 100%;
}